import logo from './logo.svg';
import './App.css';

import { useState } from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Question from './Pages/Question';
import Login from './Pages/Login';
import Register from './Pages/Register';
import Questions from './Pages/Questions';
import { Error404 } from './Pages/Error404';

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Login />}></Route>
        <Route path='/register' element={<Register />}></Route>
        <Route path='/questions' element={<Questions />}></Route>
        <Route path='/question/:id' element={<Question />}></Route>
        <Route path='*' element={<Error404 />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
