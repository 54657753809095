import React from 'react'
import { useNavigate } from 'react-router'
import Error from "../img/Error.png"
export const Error404 = () => {
    const navigate = useNavigate();
    const sendToAccueil = () => {
        navigate('/questions')
    }
    return (
        <div className="container-Reponse">
            <div className="container-Error">

                <img src={Error} className="img-error"></img>
                <h1>Oups, on dirait que la page est perdue.</h1>
                <h2>Ce n’est pas une faute, juste un accident qui n’était pas intentionnel.</h2>
                <button className="btn-erreur" onClick={sendToAccueil} > retour à la page Accueil</button>
            </div>
        </div>
    )
}
