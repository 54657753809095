import React from 'react'
import { useNavigate } from 'react-router'
import { useEffect } from 'react'
import axios from 'axios';
import { useState } from 'react';
import avatar from "../img/avatar.png"
import answer from "../img/answer.png"
import view from "../img/view.png"
import logout from "../img/logout.png"
const Questions = () => {
    const navigate = useNavigate();
    const [allQuestions, setAllQuestions] = useState([]);
    const token = (sessionStorage.getItem('token'))
    const url = `https://server.joeleprof.com/questions/`;
    const [addQuestion, setAddQuestion] = useState("");
    const getData = async () => {
        const { data } = await axios.get(`${url}${token}`);
        if (data.success) {
            setAllQuestions(data.data)
        }

    }
    const onRederectToQuestion = (id) => {

        axios.get(`https://server.joeleprof.com/questions/${id}/${token}`)
            .then(({ data }) => {

                navigate(`/question/${id}`)
            })
    }
    const showQuestions = () => {

        return allQuestions.map((question) =>
            <div className="container-All-Questions" key={question._id}>
                <div className="container-Question" key={question._id}>
                    <div>
                        <span className="question">  {question.question}</span>
                    </div>
                    <table className="tableQuestions">
                        <tbody>
                            <tr>

                                <td ><img className="img-avatar" src={avatar} alt="" /><span className="avatar-user">{question.autor.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '')} </span></td>
                                <td valign="right"> <img className="img-Info-Question" src={view} alt="" /><span className="quesion-info"> {question.views}</span></td>
                                <td><img className="img-Info-Question" src={answer} alt="" /> <span className="quesion-info">{question.answers.length}</span></td>

                            </tr></tbody>
                    </table>



                    <button className="btn-show-reponse" onClick={() => onRederectToQuestion(question._id)}>Repondre</button>

                </div>
            </div >)
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        const sendQuestion = async () => {
            if (!addQuestion.empty) {
                const url = `https://server.joeleprof.com/questions/${token}`;

                const body = {
                    'newQuestion': addQuestion
                }
                axios.post(url, body)
                    .then(({ data }) => {
                        if (data.success) {

                            window.location.reload(false);
                        }
                    })
            }
        }
        sendQuestion();
    }
    useEffect(() => {
        if (token == null) {
            return navigate('/')
        }
        getData()

    }, [])



    const deconnecter = () => {
        sessionStorage.clear()
        return navigate('/')
    }
    return (
        <div className="container-Qestions">
            <div className="nav">
                <img className="btn-deconnecter1" onClick={deconnecter} src={logout}></img>

            </div>
            <div className="Add-Question">
                <form onSubmit={handleSubmit}>
                    <table className="Add-Question">
                        <tbody>
                            <tr >
                                <td className="td1"><input value={addQuestion} type="text" className="txt-AddQuestion" onChange={(e) => setAddQuestion(e.target.value)} placeholder='Ajouter une Question' id="addQuestion" name="addQuestion" /></td>
                                <td className="td2"><button type="submit" className="btn-Add-Question" >Ajouter</button></td>
                            </tr>
                        </tbody>
                    </table>


                </form>
            </div>
            {showQuestions()}
            <br />

        </div>
    )
}

export default Questions