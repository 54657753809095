import React from 'react'
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import { browserHistory } from 'react-router';
import axios from 'axios';
import Questions from './Questions';
const Login = () => {

    const [matricule, setMatricule] = useState('');
    const [passw, setPassw] = useState('');
    const [msjError, setmsjError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        const OnConnect = async () => {
            const url = 'https://server.joeleprof.com/auth/login';

            const body = {
                'matricule': matricule,
                'password': passw
            }

            axios.post(url, body)
                .then(({ data }) => {
                    if (data.success) {
                        sessionStorage.setItem("token", data.token);

                        console.log(sessionStorage.getItem('token'));
                        axios.get(`https://server.joeleprof.com/questions/${data.token}`)
                            .then(({ data }) => {

                                navigate('/questions')
                            })
                    } else {
                        setmsjError(data.msg)

                    }
                })
        }
        OnConnect()
    }
    const register = () => {
        navigate('/register')
    }




    return (
        <div className="App">
            <div className="auth-form-container">
                <h1>Page de connexion</h1>
                <span className="error">{msjError}</span>
                <form className="login-form" onSubmit={handleSubmit}>
                    <label htmlFor="matricule">Matricule</label>
                    <input value={matricule} type="text" onChange={(e) => setMatricule(e.target.value)} placeholder='Matricule' id="matricule" name="matricule" />
                    <label htmlFor="password">Mot de passe</label>
                    <input type="password" value={passw} onChange={(e) => setPassw(e.target.value)} placeholder='Mot de passe' id="password" name="password" />
                    <button type="submit" >Connexion</button>
                </form>
                <button className="link-btn" onClick={register}> Nouveau client ? M'inscrire</button>
            </div></div>
    )
}

export default Login