import React from 'react'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import axios from 'axios'
import { useState } from 'react';
import logout from "../img/logout.png"
import avatar from "../img/avatar.png"
import Accueil from "../img/Accueil.png"
const Question = () => {

    const { id } = useParams();
    const [quest, setQuest] = useState();
    const [answers, setAnswers] = useState([]);
    const [reponse, setReponse] = useState('');
    const token = (sessionStorage.getItem('token'))
    const url = `https://server.joeleprof.com/questions/`;
    const navigate = useNavigate();

    const showAnswer = () => {
        return answers.map((reponse, index) =>
            <div className="container-All-Questions" key={index}>

                <div key={index}> <img className="img-avatar" src={avatar} alt="" /><span className="avatar-user">{reponse.autor}</span>
                    <p className="reponse-text">{reponse.answer}</p></div>
            </div>
        )
    }
    useEffect(() => {
        const getData = async () => {
            const { data } = await axios.get(`${url}${id}/${token}`);
            {
                if (!data.success) navigate('*')
                if (data.data === undefined) {
                    return setQuest("n'existe pas")
                }

                setQuest(data.data.question)
                setAnswers(data.data.answers)
            }
        }
        getData()

    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        const sendAnswer = async () => {
            if (!reponse.empty) {
                const url = `https://server.joeleprof.com/questions/${id}/create-answer/${token}`;

                const body = {
                    'newAnswer': reponse
                }
                axios.post(url, body)
                    .then(({ data }) => {
                        if (data.success) {
                            console.log(data)
                            window.location.reload(false);
                        }
                    })
            }
        }
        sendAnswer();
    }
    const deconnecter = () => {
        sessionStorage.clear()
        return navigate('/')
    }
    const accueil = () => {

        return navigate('/questions')
    }

    return (

        <div className='bBody'>
            <div className="container-Reponse">

                <table className="nav">
                    <tbody>
                        <tr>
                            <td className="img1"> <img className="btn-Accueil" onClick={accueil} src={Accueil}></img></td>
                            <td className="img2"> <img className="btn-deconnecter" onClick={deconnecter} src={logout}></img></td>
                        </tr></tbody>
                </table>

                <div className="container-question1">
                    <span className="question1"> Question : " {quest} "</span>
                </div>

                <form onSubmit={handleSubmit}>
                    <table className="Add-Question">
                        <tbody>
                            <tr>
                                <td className="td1"> <input value={reponse} className="txt-AddQuestion" type="text" onChange={(e) => setReponse(e.target.value)} placeholder='Vous avez la réponse à cette question? Répondez ici ...' id="reponse" name="reponse" /></td>
                                <td className="td2">  <button type="submit" className="btn-Add-Question">Répondre</button></td>
                            </tr></tbody>
                    </table>
                </form>
                <div className="container-All-Questions">
                    <div className="container-Question" >
                        {showAnswer()}
                    </div>
                </div>
            </div></div>
    )
}

export default Question