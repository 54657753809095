import React from 'react'
import { useState } from "react";
import { useNavigate } from 'react-router-dom'
import axios from 'axios';

const Register = (props) => {

    const [matricule, setMatricule] = useState('');
    const [passw, setPassw] = useState('');
    const [testPassw, setTestPassw] = useState('');
    const [name, setName] = useState('');
    const [msjError, setmsjError] = useState('');

    const navigate = useNavigate();

    const isValidateField = () => {
        if (testPassw.trim() != passw.trim()) {
            setmsjError("Les mots de passe doivent être identique!")
            document.getElementById("password2").style.border = '3px solid red'
            document.getElementById("password").style.border = '3px solid red'
            return false
        }
        else {
            setmsjError('')
            document.getElementById("matricule").style.border = '1px solid'
            document.getElementById("name").style.border = '1px solid'
            document.getElementById("password").style.border = '1px solid'
            document.getElementById("password2").style.border = '1px solid'
            return true
        }

    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!isValidateField()) {
            return
        }

        const onRegister = async () => {
            const url = 'https://server.joeleprof.com/auth/register';
            const body = {
                'matricule': matricule,
                "fullName": name,
                'password': passw
            }

            axios.post(url, body)
                .then(({ data }) => {
                    if (data.success) {
                        sessionStorage.setItem("token", data.token);

                        axios.get(`https://server.joeleprof.com/questions/${data.token}`)
                            .then(({ data }) => navigate('/questions'))
                    } else {
                        setmsjError(data.msg)

                    }
                })
        }
        onRegister()

    }
    const navigateToLogin = () => {
        navigate('/')

    }

    return (
        <div className="App">
            <div className="auth-form-container">
                <h1>Page d'inscription</h1>
                <span className="error">{msjError}</span>
                <form className="register-form" onSubmit={handleSubmit}>
                    <label htmlFor="matricule">Matricule</label>
                    <input value={matricule} type="text" onChange={(e) => setMatricule(e.target.value)} placeholder='Matricule' id="matricule" name="matricule" />

                    <label htmlFor="name">Nom Complet</label>
                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder='Votre nom complet' id="name" name="name" />

                    <label htmlFor="password">Mot de passe</label>
                    <input type="password" value={passw} onChange={(e) => setPassw(e.target.value)} placeholder='Mot de passe' id="password" name="password" />

                    <label htmlFor="password">Confirmer Mot de passe</label>
                    <input type="password" text={testPassw} onChange={(e) => setTestPassw(e.target.value)} placeholder='Confirmer Mot de passe' id="password2" name="password2" />

                    <button type="submit">Connexion</button>
                </form>
                <button className="link-btn" onClick={navigateToLogin}> Déjà client ? Se connecter</button>
            </div></div>
    )
}

export default Register